<template>
        <Modal v-model="display" title="创建直播间" @on-ok="ok" @on-cancel="cancel">
            <div class="content">
                 <Form :model="form" label-position="top" >
                    <FormItem label="品牌名称">
                        <Input v-model="form.brand_name"></Input>
                    </FormItem>
                    <FormItem label="直播主题：">
                        <Input v-model="form.room_name"></Input>
                    </FormItem>
                    <FormItem label="开播时间:">
                        <DatePicker :value="form.start_time" @on-change="handleChange"  type="datetime" format="yyyy-MM-dd HH:mm" placeholder="选择开播时间"></DatePicker>
                    </FormItem>
                    <FormItem label="模式:">
                        <RadioGroup v-model="form.scenes">
                            <Radio label="1" checked>横屏</Radio>
                            <Radio label="0">竖屏</Radio>
                        </RadioGroup>
                    </FormItem>
                </Form>
            </div>
        </Modal>
</template>

<script>
export default {
    name: 'MashPushCreateBrand',
    data() {
        return {
            display: false,
            form: {
                brand_name:'',
                room_name:'',
                scenes:'1',
                start_time:'',
                anchor_name:'主播老师',
            }
        };
    },
    mounted() {
        
    },
    methods: {
        switchDisplay() {
            this.display = !this.display;
        },
        handleChange(date){
            this.form.start_time = date;
        },
        ok(){

            if(!this.form.brand_name){
                this.$Message.error('请输入品牌名称!');
                return
            }

            if(!this.form.room_name){
                this.$Message.error('请输入直播间名称!');
                return
            }

            if(!this.form.scenes){
                this.$Message.error('请选择直播模式!');
                return
            }

            if(!this.form.start_time){
                this.$Message.error('请选择开播时间!');
                return
            }
            this.$api.createBrand({
                site_name: this.form.brand_name
            }).then((brandRes) => {
                if (brandRes.code >= 0) {
                    this.$api.getBrandToken({
                        site_id: brandRes.data
                    }).then((tokenRes) => {
                        if (tokenRes.code >= 0) {
                            this.$api.createRoom({
                                name:this.form.room_name,
                                scenes:this.form.scenes,
                                anchor_name:this.form.anchor_name,
                                start_time:this.form.start_time,
                                token:tokenRes.data
                            }).then((res) => {
                                if (res.code >= 0) {
                                    this.$emit('success')
                                    this.$Message.success('创建成功!');
                                }
                            })
                        }
                    });
                }
            });
        },
        cancel(){

        },
    },
};
</script>

<style lang="scss" scoped>

</style>