<template>
        <Modal v-model="display" title="创建直播间" @on-ok="ok" @on-cancel="cancel">
            <div class="content">
                 <Form :model="form" label-position="top" >
                    <FormItem label="直播主题：">
                        <Input v-model="form.name" placeholder="请输入直播间主题名称"></Input>
                    </FormItem>
                    <FormItem label="开播时间:">
                        <DatePicker :value="form.start_time" @on-change="handleChange" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="选择开播时间"></DatePicker>
                    </FormItem>
                    <FormItem label="模式:">
                        <RadioGroup v-model="form.scenes">
                            <Radio label="1" checked>横屏</Radio>
                            <Radio label="0">竖屏</Radio>
                        </RadioGroup>
                    </FormItem>
                </Form>
            </div>
        </Modal>
</template>

<script>
export default {
    name: 'MashPushCreateRoom',
    data() {
        return {
            display: false,
            form: {
                name:'',
                cover_img:'',
                scenes:'1',
                start_time:'',
                anchor_name:'主播老师',
                room_logo:'',
            }
        };
    },
    mounted() {
        
    },
    methods: {
        switchDisplay() {
            this.display = !this.display;
        },
        handleChange(date){
            this.form.start_time = date;
        },
        ok(){
            this.$api.createRoom({
                name:this.form.name,
                cover_img:this.form.cover_img,
                scenes:this.form.scenes,
                start_time:this.form.start_time,
                anchor_name:this.form.anchor_name,
                room_logo:this.form.room_logo
            }).then((res) => {
                if (res.code >= 0) {
                    this.$Message.success('创建成功!');
                    this.$emit('success')
                }else {
                    this.$Message.error(res.message);
                }
            })
        },
        cancel(){

        },
    },
};
</script>

<style lang="scss" scoped>

</style>